import styled from "styled-components"
import { darken, lighten } from "polished"
import { Colors } from "../assets/constants/colors"
// import styledProps from "styled-props";

export const Button = styled.button<{color?: keyof typeof Colors}>`
    padding: 16px 24px;
    background-color: ${props => props.disabled ? lighten(0.2, Colors[props.color]) : Colors[props.color]};
    color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    min-height: 48px;

    :active{
        background-color: ${props => props.disabled ? lighten(0.2, Colors[props.color]) : darken(0.2, Colors[props.color])};
    }
`

Button.defaultProps = {
    color: "primary"
}

export const BlockButton = styled(Button)`
    width: 100%;
`