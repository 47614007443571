import { i18n } from "@cloposcom/libs";
import { useEffect, useReducer } from "react";
import { api } from "./api";
import { getDataFromLocation, getTipOptions } from "./utils";
import * as Sentry from "@sentry/react";

const reducer = (state: IPaymentState, action: any): IPaymentState => {
    switch (action.type) {
        case "start":
            return { ...state, message: i18n.cLang().loading, status: "loading", ...action.payload };
        case "brand":
            return { ...state, brand: action.brand };
        case "transaction":
            return {
                ...state,
                status: "success",
                message: i18n.cLang().paid,
                ...action.payload,
            };
        case "error":
            return {
                ...state,
                status: "error",
                message: action.message,
            };
        default:
            return { ...state, status: "idle" };
    }
};

export interface IPaymentState {
    status: string;
    brand?: IBrand;
    paymentStatus?: string;
    transaction?: ITransaction;
    message?: string;
    declined?: boolean;
    data: ReturnType<typeof getDataFromLocation>;
    tips: ReturnType<typeof getTipOptions>;
}

const initialState: IPaymentState = {
    status: "idle",
    message: "Loading...",
} as any;

export function usePaymentData() {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const data = getDataFromLocation();
        if (!data) {
            Sentry.captureException("No payment hash");
            dispatch({ type: "error", message: "No payment hash" });
            return;
        }

        const tips = getTipOptions(data.total);

        if (!data.receipt_cid) {
            Sentry.captureException("URL is malformed");
            dispatch({ type: "error", message: "URL is malformed" });
            return;
        }

        dispatch({ type: "start", payload: { data, tips } });

        // retrieve brand data
        api.getBrandStageAndName(data.brand_id)
            .then(async ({ name, stage }) => {
                api.brand = name;
                api.stage = stage ?? "";
                const brandData = await api.getBrandData();
                Sentry.setTag("brand", name);
                Sentry.setTag("receipt_cid", data.receipt_cid);
                dispatch({ type: "brand", brand: brandData.data?.[0] });

                // retrieve receipt payment status
                api.checkPaymentStatus(data.receipt_cid)
                    .then(resp => {
                        dispatch({
                            type: "transaction",
                            payload: {
                                transaction: resp.data.data.transaction,
                                paymentStatus: resp.data.success,
                            },
                        });
                    })
                    .catch(err => {
                        dispatch({ type: "error", message: err.message || i18n.cLang().error });
                    });
            })
            .catch(err => {
                dispatch({ type: "error", message: err.message || i18n.cLang().error });
            });
    }, []);

    return { state, dispatch };
}
