import styled from "styled-components";
import { Colors } from "../assets/constants/colors";


export const FullDiv = styled.div<{color?: keyof typeof Colors}>`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: ${props => Colors[props.color!]};
color: #fff;
`

FullDiv.defaultProps = {
    color: "primary"
}

export const Center = styled.div<{dir?: "row" | "column"}>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${props => props.dir || "column"};
    margin-top: 64px;
`

export const VersionFooter = styled.div`
    padding: 3px;
    color: #3338;
    text-align: center;
    font-size: 12px;
`

export const Rate = styled.div`
     width: 100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin: 64px 0;
`