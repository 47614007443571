import * as React from "react";
import { useEffect } from "react";
import svg from "../assets/svg/checkmark.svg";
import { FullPage } from "../components/FullPage";
import { Header } from "../components/Header";
import { Center, Rate } from "../components/misc";
import { IPaymentState } from "../utils/hooks";
import start from "../assets/svg/star.svg";
import arrowRight from "../assets/svg/arrowRight.svg";
import cloposLogo from "../assets/svg/cloposLogo.svg";
import payriff from "../assets/svg/payriff.svg";

import { getRateUrl } from "../utils/utils";

export const SuccessPage: React.FC<{ state: IPaymentState }> = ({ state }) => {
    const Htag = {
        margin: "0 0 6px 0",
        padding: "0",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#344255",
    };
    const ptag = {
        margin: "0",
        padding: "0",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#64748B",
    };
    const center = {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        transform: "scale(0.7)",
    };

    const referrer = localStorage.getItem("referrer");

    useEffect(() => {
        const handleBackButton = (event: any) => {
            event.preventDefault();
            goBack();
        };

        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, []);

    const goBack = () => {
        const url = new URL(window.location.href);
        window.location.href = referrer + (referrer.endsWith("/") ? "" : "/") + "links" + url.search;
    };

    if (!state.transaction) {
        return <FullPage error>No transaction</FullPage>;
    }

    return (
        <div style={{ justifyContent: "space-evenly" }} className="App">
            <Header
                goBack={goBack}
                referrer={referrer}
                logo={state?.brand.logo?.urls?.logo ?? state?.brand.logo?.urls?.original}
                title={state.brand.brand_name}
            />
            <Center>
                <img src={svg} alt="Success" style={{ width: 100 }} />
                <br />
                <span style={{ fontSize: 20 }}>{state.message}:</span>
                <strong style={{ fontSize: 24 }}>{parseFloat(state.transaction.amount as any).toFixed(2)} ₼</strong>
            </Center>

            <Rate
                style={{ border: "1px solid #E2E8F0", borderRadius: "12px", padding: "19px 46px 19px 19px" }}
                onClick={() => {
                    window.location.href = getRateUrl(state.data.receipt_cid, state.data.brand_id);
                }}
            >
                <div style={{ display: "flex" }}>
                    <img src={start} alt="star-logo" />
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px", textAlign: "left" }}>
                        <h4 style={Htag}>Xidməti dəyərləndirin</h4> <p style={ptag}>Qeydləriniz bizim üçün önəmlidir</p>
                    </div>
                </div>
                <img src={arrowRight} alt="arrow-logo" />
            </Rate>
            <div style={center}>
                <img style={{ width: "128px", height: "28px" }} src={cloposLogo} alt="Logo" />
                <img style={{ marginLeft: "1rem", width: "128px", height: "28px" }} src={payriff} alt="" />
            </div>
        </div>
    );
};
