import * as React from "react";
import { CSSProperties } from "styled-components";
import "./AnimatedArrows.css";

interface IProps {
    dir: "Left" | "Right";
    count: number;
    style?: CSSProperties;
}

export const AnimatedArrows: React.FC<IProps> = (props) => {
    const style = props.dir === "Right" ? {
        ...props.style,
        transform: "rotate(180deg)"
    } : props.style;

    return <div id="arrowAnim" style={style}>
        {Array(props.count).fill(0).map((v, i) =>
            <div key={i} className={`arrowSliding delay${i}`}>
                <div className={`arrow`}></div>
            </div>)}
    </div>
}