import styled, { keyframes } from "styled-components";

export const BlueBox = styled.div`
background: aliceblue;
margin: 16px 0;
padding: 16px;
color: #333a;
border-radius: 7px;
text-align: center;
`

export const YellowBox = styled(BlueBox)`
    background-color: #fff0cc;
`

export const RedBox = styled(BlueBox)`
    background-color: #ffdddd;
`

export const TotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  margin: 2px 0;
`

export const Tip = styled.div`
    display: flex;
    margin: 22px 0;
`
export const Tipcontent = styled.div`
    margin-left: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
export const TipcontentHeader = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #344255;
`
export const TipcontentParagraph = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #64748B;
    margin-top: 4px;
`
const iconanimate = keyframes`
   from {
        opacity: 0;
        transform: scale(0.7);
    }
 
      to {
        opacity: 1;
        transform: scale(1);
    }
`;
export const TipIcons = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: calc(20vh - 40px);
   flex: 0 1 auto;
 
`
export const TipIconImg = styled.img`
    animation: ${iconanimate} 0.3s;
`





