import loading from "../assets/svg/loading.svg";
import success from "../assets/svg/checkmark.svg";
import error from "../assets/svg/error.svg";
import { FullDiv } from "./misc";

interface IProps {
    loading?: boolean;
    success?: boolean;
    error?: boolean;
    children?: React.ReactNode;
}

export const FullPage: React.FC<IProps> = (props) => <FullDiv>
    {props.loading && <img src={loading} alt="Loading" style={{ width: 50 }} />}
    {props.success && <img src={success} alt="Success" style={{ width: 50 }} />}
    {props.error && <img src={error} alt="Error" style={{ width: 50 }} />}

    <br />
    {props.children}
</FullDiv>