import axios from "axios";
import { throws } from "node:assert";

export const api = {
    brand: "",
    stage: "",
    brandUrl: (path: string) =>
        `https://${api.brand}.clopos.com/api${api.stage === "prod" ? "" : api.stage ? "-" + api.stage : ""}/${path}`,
    getBrandStageAndName: async (id: number) => {
        const data = await axios.get(`https://api.clopos.com/api/service/brand-id/${id}`).then(data => data.data);
        if (!data.success) {
            throw new Error("Brand not found");
        }
        return {
            name: data.data.name,
            stage: data.data.stage,
        };
    },
    getBrandData: () => {
        return axios.get(api.brandUrl("check")).then(data => data.data);
    },
    checkPaymentStatus: (receipt_cid: string) => {
        return axios.post(api.brandUrl("pay/check-status"), { receipt_cid });
    },
    register: (data: any) => {
        return axios.post(api.brandUrl("pay/register"), data);
    },
};
