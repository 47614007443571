import { i18n } from '@cloposcom/libs';
import React, { FC, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import closeIcon from "../../assets/svg/close.svg"
type Props = {
    open: boolean;
    input: string
    setOpen: (value: boolean) => void;
    setTip: (value: any) => void;
    setInput: (value: any) => void;
    total?: number

}

const TipModal: FC<Props> = ({ open, setOpen, setTip, setInput, input, total }) => {
    const [inputvalue, setInputvalue] = useState<any>();

    
    function rejectTip() {
        setOpen(!open);
        setInput(0);
        setTip(0);
        setInputvalue(null)
    }
    function confirmTip() {
        if(inputvalue > total){
            return false
        }
        setInput(inputvalue);
        setTip(parseFloat(inputvalue || 0));
        setOpen(!open)
    }

    return (
        <>
            {open ? <ModalBackground>
                <Modal open={open}>
                    <CloseIcon onClick={() => setOpen(!open)} >
                        <img src={closeIcon} alt="" />
                    </CloseIcon>

                    <Modalheader>
                    {i18n.cLang().tip_for_waiter}
                    </Modalheader>

                    <ModalLabel >
                        <TipInput step="any" min="0" onChange={(e: any) => setInputvalue(e.currentTarget.value)} required type="number" value={inputvalue}
                        />
                       
                        <ModalPlaceHolder> {i18n.cLang().tip_amount}, ₼ </ModalPlaceHolder>

                    </ModalLabel>
                    { inputvalue > total 
                     ?  <ErrorText style={{color:'red', float:"left", textAlign:"left", width:"100%" }}> {i18n.cLang().total_tip_exception}</ErrorText> 
                     :  null 
                    }
                    {/* <ModalContent>Lorem ipsum dolor sit a met</ModalContent> */}

                    <ModalFooter>
                        <Reject onClick={rejectTip}>{i18n.cLang().reject}</Reject>
                        <Confirm onClick={confirmTip}>{i18n.cLang().tip_confirm}</Confirm>
                    </ModalFooter>
                </Modal>
            </ModalBackground>
                : null}

        </>
    )
}


const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(15px);
    `

const scalemodal = keyframes`
   from {
        opacity: 0;
        transform: scale(0.7);
    }
 
      to {
        opacity: 1;
        transform: scale(1);
    }
`;

const Modal = styled.div<{ open }>`
    background-color: #fff;
    position: relative;
    width: 280px;
    height: 295px;
    border-radius: 16px;
    display: flex; 
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    animation: ${scalemodal} 0.3s;
    margin-top: -10rem;
    
    `
const Modalheader = styled.h2`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #1E293B;
    text-align: left;
    padding-top: 15px;
     
    `

const ModalLabel = styled.label`
        width: 100%;
        position: relative;
       
    `
const ModalPlaceHolder = styled.div`
        position: absolute;
        top: 0;
        left: 16px;
        transition: 0.3s;
    `

const TipInput = styled.input`
        border: 1px solid #E2E8F0;
        border-radius: 8px;
        width: 240px;
        height: 56px;
        padding: 19px 16px 0 19px;
        margin-top: -15px;
        font-size: 17px;
        
         &:valid + div{
           scale: 0.75;
           top: -11px;
           left: 5px;
           color: gray;
       }
       &:focus + div{
           scale: 0.75;
           top: -8px;
           left: 5px;
           color: gray;
       }
    `

const focusInput = css`
        &:focus + div{
           scale: 0.75;
           top: -14px;
           left: 5px;
           color: gray;
       }
    `
const ModalFooter = styled.footer`
        display: flex;
        justify-content: space-between;
    `
const DesicionButton = styled.button`
        width: 114px;
        height: 48px;
        border-radius: 8px;
        border: none;
        color: #EDF2FF;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.3px;
    `
const Reject = styled(DesicionButton)`
    background-color: #EDF2FF;
    color: #1C6EEB;
    `
const Confirm = styled(DesicionButton)`
    background-color: #1C6EEB;
    `
const ModalContent = styled.p`
    text-align: left;
     margin: 0;
     font-weight: 400;
     font-size: 12px;
     line-height: 14px;
     letter-spacing: 0.3px;
     color: #64748B;
     margin-top: -25px;
    `
const CloseIcon = styled.div`
       position: absolute;
       top: 0;
       right: 0;
       margin: 20px;
       width: 2rem;
       height: 2rem;
       display: grid;
       place-items: center;
   
     `
     const ErrorText = styled.span`
      margin: -1.3rem 4rem 0 0;
      padding: 0;
      font-size: 12px;
      
     `

export default TipModal