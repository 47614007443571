import { baseConvert, i18n } from "@cloposcom/libs";
import { PaymentPage } from "./pages/PaymentPage";
import { FullPage } from "./components/FullPage";
import { useEffect, useState } from "react";

(window as any).base = baseConvert;

function App() {
    // get pathname and trim slashes
    const p = window.location.pathname.replace(/(^\/)|(\/$)/g, "");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        i18n.checkTranslateUpdate("","CloposPay").then(() => {
            i18n.setCLang("az");
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <FullPage loading />;
    }

    return <Router p={p} />;
}

function Router({ p }: { p: string }) {
    if (p === "p") return <PaymentPage />;
    else return <FullPage>Hi</FullPage>;
}

export default App;
