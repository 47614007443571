import { baseConvert, expandShortV4 } from "@cloposcom/libs";

/**
 * data by index
 *  0: structure_version. currently 1
 *  1: brand_id
 *  2: waiter_id
 *  3: total to be paid (multipled by 100. ex. 535 is 5.35 AZN )
 *  4: receipt_cid shortened cid
 */
export const getDataFromLocation = () => {
    const params = new URLSearchParams(window.location.search.substring(1));
    const hash = params.get("h");

    if (!hash) {
        return null;
    }

    const arr = hash.split(":");

    return {
        v: parseInt(baseConvert(arr[0], "safe", 10)),
        brand_id: parseInt(baseConvert(arr[1], "safe", 10)),
        waiter_id: parseInt(baseConvert(arr[2], "safe", 10)),
        total: parseInt(baseConvert(arr[3], "safe", 10)) / 100,
        receipt_cid: expandShortV4(arr[4]),
        venue_id: parseInt(baseConvert(arr[5], "safe", 10)),
        declined: params.get("status") === "fail",
        hash: hash,
    };
};

export const roundAndClean = (val: number) => Math.round(parseFloat(val.toFixed(2)));

export const getBrand = (): string => {
    const _brandName = window.location.pathname.split("/")[1];
    return _brandName !== "login" ? _brandName : "";
};

export const getTipOptionsOld = (total: number) => {
    const res: Record<string, number[]> = {
        _: [0],
        p5: [],
        p10: [],
        all: [],
    };

    let ceil = Math.ceil(total) - total;
    if (ceil < 0.5) {
        ceil += 0.5;
    }
    res._.push(roundAndClean(ceil));

    // if (total > 10) {
    if (total % 10 > 5) {
        res._.push(roundAndClean(10 - (total % 10)));
    } else {
        res._.push(roundAndClean(5 - (total % 10)));
    }
    // }
    const p5 = roundAndClean(total * 0.05);
    if (p5 > ceil) {
        res.p5.push(p5);
        if (p5 % 10 !== 0) {
            res.p5.push(Math.ceil(p5));
        }
    }

    const p10 = roundAndClean(total * 0.1);
    if (p10 > ceil) {
        res.p10.push(p10);
        if (p10 % 10 !== 0) {
            res.p10.push(Math.ceil(p10));
        }
    }
    const p15 = roundAndClean(total * 0.1);
    if (p15 > ceil) {
        res.p15.push(p15);
        if (p15 % 15 !== 0) {
            res.p15.push(Math.ceil(p15));
        }
    }

    res.all = Object.keys(res).reduce((s, c) => s.concat(res[c]), res.all);

    return res;
};

export const getTipOptions = (total: number) => {
    const p5 = total * 0.05 < 0.5 ? 0.5 : total * 0.05;
    const p10 = total * 0.1 < 1 ? 1 : total * 0.1;
    const p15 = total * 0.15 < 1.5 ? 1.5 : total * 0.15;

    return [
        { value: 0, label: "0%" },
        { value: roundAndClean(p5), label: "5%" },
        { value: roundAndClean(p10), label: "10%" },
        { value: roundAndClean(p15), label: "15%" },
    ];

    // return [
    //     { value: 0, label: "No tip" },
    //     { value: roundAndClean(p5), label: "( 5% )" },
    //     { value: roundAndClean(p10), label: "( 10% )" },
    // ];
};

export function selectTip(total: number, tips: number[]) {
    const p5 = total * 0.05;
    tips = tips.slice(1);
    const diffs = tips.map(val => Math.abs(val - p5));
    const min = Math.min(...diffs);
    return tips[diffs.indexOf(min)];
}

export function getRateUrl(cid, brand_id) {
    return `https://clps.cc/r/${cid.replaceAll("-", "")}${brand_id}`;
}
