import styled from "styled-components";
import editIcon from "../assets/svg/edit.svg"

const color = {
    active: "#1C6EEB",
    muted: "#CBD5E1"
}

interface IButtonOptionProps {
    active?: boolean;
    isBest?: boolean;
}

// export const ButtonOption = styled.button<IButtonOptionProps>`
//     border: 2px solid;
//     color: ${props => props.active ? "#fff" : "#666"};
//     border-color: ${props => props.active ? color.active : color.muted};
//     border-radius: 7px;
//     min-height: 49px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     flex: 1;
//     background-color: ${props => props.active ? color.active : "#fff"};
//     font-size: 16px;
//     font-weight: 500;
//     white-space: nowrap;
//     min-width: 27%;
//     padding: 10px 6px;
//     position: relative;
//     gap: 4px;

//     :after {
//         content: '${props => props.isBest ? "⭐" : null}';
//         position: absolute;
//         top: -13px;
//         right: -12px;
//     }

//     > div {
//         font-size: 12px;
//         color: ${props => props.active ? "#fffa" : "#666a"};
//     }
// `
export const ButtonOption = styled.button<IButtonOptionProps>`
  color: ${props => props.active ? "#fff" : "#344255"};
  border-radius: 12px;
  min-height: 49px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 59px;
  height: 58px;
  background-color: ${props => props.active ? color.active : "#F1F5F9"};
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  border: none;
  padding: 10px 6px;
  position: relative;
  gap: 4px;

  :after {
    content: '${props => props.isBest ? "⭐" : null}';
    position: absolute;
    top: -13px;
    right: -12px;
  }

  > div {
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.active ? "#fff" : "#344255"};
  }
`


const ButtonSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

interface IButtonSelectProps {
    options: Array<{
        value: number;
        label: string;
    }>;
    onChange: (value: number, index: number) => void;
    active: number;
    activeIndex: number;
    currency: string;
    setOpen: (value: boolean) => void;
    open: boolean;
    writtenTip?: any
}

export const ButtonSelect: React.FC<IButtonSelectProps> = (props) => {

    if (props.options.length === 0) {
        return null;
    }

    const onClick = (val: number, index: number) => () => {
        props.onChange(val, index);
    }

    const color = "invert(13%) sepia(96%) saturate(746%) hue-rotate(315deg) brightness(428%) contrast(255%)";

    return (
        <ButtonSelectContainer id="tip-buttons">
            {props.options.map((val, index) =>
                <ButtonOption key={val.value}
                              active={val.value === props.active && props.activeIndex === index}
                              onClick={onClick(val.value, index)}>
                    {val.value ? <div>
                        {val.label}
                    </div> : null}
                    <span>
                    {val.value === 0 ? `0₼` : Math.round(val.value) + " " + "₼"}
                </span>

                </ButtonOption>)}
            <ButtonOption style={{background: props.writtenTip ? "#1C6EEB" : ""}}
                          onClick={() => props.setOpen(!props.open)}>
                <img style={{filter: props.writtenTip ? color : ""}} src={editIcon} alt=""/>
                {props.writtenTip ?
                    <span style={{color: props.writtenTip ? "#fff" : ""}}>{props.writtenTip} ₼</span> : null}
            </ButtonOption>
        </ButtonSelectContainer>
    );
}