import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: "https://e2e527e8e36215fcb58fd9540d9ebe3d@o284608.ingest.sentry.io/4505685539160064",
    integrations: [new Integrations.BrowserTracing()],
    // Performance Monitoring
    tracesSampleRate: 1.0,
});

if (document.referrer && new URL(document.referrer).hostname.includes("clopos")) {
    localStorage.setItem("referrer", document.referrer);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
