import * as React from "react";
import styled from "styled-components";
import { Colors } from "../assets/constants/colors";
// import logo from "../assets/svg/logo.svg";
import logoDefault from "../assets/svg/logoDefault.svg";

const BrandName = styled.div`
    background-image: linear-gradient(to right, ${Colors.primary} 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 9px 2px;
    background-repeat: repeat-x;
    text-align: center;
    height: 13px;
    margin-top: 12px;
    margin-bottom: 36px;

    :after {
        content: "${props => props.title}";
        display: inline-block;
        margin-bottom: -10px;
        padding: 0 10px;
        text-transform: capitalize;
        background-color: #fff;
        font-size: 24px;
        color: #1f274f;
        font-weight: 700;
    }
`;
const BrandIcon = styled.div`
    width: 84px;
    height: 84px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #e2e8f0;
`;

const Button = styled.button`
    position: absolute;
    left: 10px;
    top: 30px;
    background-color: transparent;
    border: unset;
`;

export const Header: React.FC<{
    title: string;
    logo?: any;
    referrer?: string;
    goBack?: () => void;
}> = ({ title, logo, referrer, goBack }) => {
    return (
        <header
            className="App-header"
            style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "1rem",
            }}
        >
            {referrer ? (
                <Button onClick={() => goBack?.()}>
                    <img src="chevron-left.svg" alt="chevron-left" />
                </Button>
            ) : null}
            <BrandIcon>
                <img
                    src={logo ? logo : logoDefault}
                    className="img-responsive"
                    alt="logo"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
            </BrandIcon>

            <BrandName title={title} />
        </header>
    );
};
